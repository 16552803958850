import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';
import MetaTags from '../../components/MetaTags';
import { theme } from '../../styles/theme';
import Background from '../../components/Background';
import Header from '../../components/Header2';
import HowGroupGiftsWork from '../../sections/occasion/HowGroupGiftsWork';
import CuratedGifts from '../../sections/occasion/CuratedGifts';
import GroupCard from '../../sections/occasion/GroupCard';
import LoveIllume from '../../sections/occasion/LoveIllume';
import JoinOccasions from '../../sections/JoinOccasions';
import Footer from '../../sections/common/FooterWithNav';
import data from '../../sections/marketing/data';
import futureCelebrating from '../../images/occasion/fathers-day.png';
import futurePhone from '../../images/occasion/iPhone12.png';
import HeaderSection from '../../sections/occasion/HeaderSection';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const OccassionFathersDay: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='Occasions | illume'
        description={'Create simple, meaningful Father’s Day ecards and gifts with illume.'}
      />
      <Layout>
        <Background showTopBlob={false} />
        <Header />
        <HeaderSection
          heading={"Sincere Father's Day Gifts"}
          paragraphs={[
            'Father’s Day is around the corner. Joey texts his sister Jill in a scramble. She tells him not to panic: She’s creating a group gift with illume. After selecting a unique gift from illume’s digital marketplace, she invites Joey (plus Mom and Grandpa) to contribute with cash, messages, photos, and more.',
            'With just a few clicks, Jill schedules their group gift to arrive on Father’s Day – that easy.',
          ]}
          buttonLink={'https://link.illumenotes.com/8cxgA7KtCuQd6uFV6'}
          images={{
            celebration: {
              image: futureCelebrating,
              label: 'Fathers Day',
            },
            phone: {
              image: futurePhone,
              label: 'Fathers Day Mobile',
            },
          }}
        />
        <HowGroupGiftsWork />
        <CuratedGifts gifts={data.gifts} />
        <GroupCard />
        <LoveIllume testimonials={data.testimonials} />
        <JoinOccasions 
            joinMessage={'of celebrating Father\'s Day'}
        />
        <Footer links={data.links} />
      </Layout>
    </ThemeProvider>
  );
};

export default OccassionFathersDay;
