
const headingTexts: string[] = [
  'birthdays',
  'farewells',
  'thank you’s',
  'get well’s',
  'anniversaries',
  'any occasion'
];

export default headingTexts;