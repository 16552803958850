
// TODO: Fill in references...
const data = [
    { label: 'FAQs', reference: '' },
    { label: 'Contact Us', reference: '' },
    { label: 'Merchants', reference: '' },
    { label: 'Blog', reference: '' },
    { label: 'About', reference: '' },
    { label: 'Teams', reference: '' },
    { icon: '', label: 'Our Playlist', reference: '' }
  ];
  
  export default data;