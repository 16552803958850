import { ITestimonial } from '../../common/types';

import lake_view from '../../../images/testimonials-v2-rawr/lakeside_view.png';
import brightland_bottles from '../../../images/testimonials-v2-rawr/brightland_bottles.png';
import chips_and_cookies from '../../../images/testimonials-v2-rawr/chips_and_cookies.png';
import donuts from '../../../images/testimonials-v2-rawr/donuts.png';
import facial from '../../../images/testimonials-v2-rawr/facial.png';
import mugs from '../../../images/testimonials-v2-rawr/mugs.png';
import presents from '../../../images/testimonials-v2-rawr/presents.png';
import students_hurray from '../../../images/testimonials-v2-rawr/students_hurray.png';
import two_women from '../../../images/testimonials-v2-rawr/two_women.png';
import woman_back_portrait from '../../../images/testimonials-v2-rawr/woman_back_portrait.png';
import women_with_balloons from '../../../images/testimonials-v2-rawr/women_with_balloons.png';

// anne.png  daniela.png  jordan.png  laura.png  matt.png  morgan.png  ruth.png  ryan.png  sarah.png  tanya.png  tony.png
import sarah from '../../../images/avatars/sarah.png';
import daneila from '../../../images/avatars/daniela.png';
import jordan from '../../../images/avatars/jordan.png';
import laura from '../../../images/avatars/laura.png';
import matt from '../../../images/avatars/matt.png';
import morgan from '../../../images/avatars/morgan.png';
import ruth from '../../../images/avatars/ruth.png';
import ryan from '../../../images/avatars/ryan.png';
import tanya from '../../../images/avatars/tanya.png';
import tony from '../../../images/avatars/tony.png';
import anne from '../../../images/avatars/anne.png';
import antonio from '../../../images/avatars/antonio.png';

class User {
  image: string;
  firstName: string;
  title: string;
  city: string;
  state: string;
  lastName?: string;
  constructor(
    image: string,
    firstName: string,
    title: string,
    city: string,
    state: string,
    lastName?: string
  ) {
    this.image = image;
    this.firstName = firstName;
    this.title = title;
    this.city = city;
    this.state = state;
    this.lastName = lastName;
  }
  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}

export const USERS = {
  sarah: new User(sarah, 'Sarah', 'Gift Recipient', 'Los Angeles', 'CA'),
  jordan: new User(jordan, 'Jordan', 'Team Manager', 'Austin', 'TX'),
  laura: new User(laura, 'Laura', 'Gift Giver', 'Chicago', 'IL', 'Rath'),
  anne: new User(anne, 'Anne', 'Gift Recipient', 'NYC', 'NY'),
  daniela: new User(daneila, 'Daniela', 'Gift Recipient', 'San Francisco', 'CA'),
  ryan: new User(ryan, 'Ryan', 'Gift Givers', 'Cleveland', 'OH'),
  ruth: new User(ruth, 'Ruth', 'Team Manager', 'Los Alton', 'CA'),
  tanya: new User(tanya, 'Tanya', 'Gift Givers', 'Houston', 'TX'),
  tony: new User(tony, 'Tony', 'Gift Recipient', 'Mountain View', 'CA'),
  morgan: new User(morgan, 'Morgan', 'Team Manager', 'Providence', 'RI'),
  matt: new User(matt, 'Matt', 'Gift Givers', 'Seattle', 'WA'),
  antonio: new User(antonio, 'Antonio', 'Gift Recipient', 'San Francisco', 'CA'),
};

export const testimonialsV2: ITestimonial[] = [
  {
    imageLarge: lake_view,
    alt: 'lake view',
    copy: 'I received this great Airbnb gift from my friends, and WOW! This made me so emotional️, it was so unexpected and sweet that they put it together!',
    user: USERS.sarah,
  },
  {
    imageLarge: women_with_balloons,
    alt: 'woman with balloons',
    copy: 'I was blown away at how easy it was to connect with my remote team using illume. From recognizing birthdays to big milestones in our work, illume has become our go-to for celebration',
    user: USERS.jordan,
  },
  {
    imageLarge: two_women,
    alt: 'Two women embracing',
    copy: 'Since undergrad, my closest girlfriends and I have moved all over the globe. We can customize illume’s group cards to feel so close and personal, even though we’re spread apart.',
    user: USERS.laura,
  },
  {
    imageLarge: facial,
    alt: 'woman getting a facial',
    copy: 'My friends used illume to send me a gift card for a Heyday facial! The nicest self-care gift that I wouldn’t have done for myself!',
    user: USERS.anne,
  },
  {
    imageLarge: donuts,
    alt: 'donuts',
    copy: 'I’ve felt a little homesick since moving across the country last year. My family used illume to surprise me with a dozen bagels from my favorite NYC deli. BEST GIFT EVER!!!',
    user: USERS.daniela,
  },
  {
    imageLarge: woman_back_portrait,
    alt: 'woman back portrait',
    copy: 'It’s amazing how one group card or gift can make someone’s day. illume makes it easy to stay positive on our team.',
    user: USERS.ruth,
  },
  {
    imageLarge: brightland_bottles,
    alt: 'brightland bottles',
    copy: 'My coworkers used illume to gift me a Brightland Olive Oil set for my birthday and I’m obsessed. Would never have discovered this on my own!',
    user: USERS.tanya,
  },
  {
    imageLarge: mugs,
    alt: 'mugs',
    copy: 'We sent a little “bon voyage” card to a colleague as she left on vacation... It was a hit! Now it’s become a tradition on our team. illume is fun, efficient, and user-friendly',
    user: USERS.tony,
  },
  {
    imageLarge: students_hurray,
    alt: 'students cheering',
    copy: 'I’m the office manager at a local arts non-profit. I use illume to create e-cards in bulk so we can celebrate moments big and small across our team.',
    user: USERS.morgan,
  },
  {
    imageLarge: presents,
    alt: 'presents',
    copy: 'For my sister’s 30th, I wanted to go BIG but I didn’t have the $$ to do it alone. illume’s gift URL made it easy to split the cost with family while also give her something truly unique.',
    user: USERS.ryan,
  },
  {
    imageLarge: chips_and_cookies,
    alt: 'chips and cookies',
    copy: 'Now that I’ve discovered illume’s curated marketplace, I’m sending more thoughtful and memorable gifts. And honestly, it’s a more enjoyable and easier search!',
    user: USERS.matt,
  },
];
