import footer from '../../common/data/footer';
import marketplace_products from '../../common/data/gifts';
import topics from '../../common/data/topics';
import headingTexts from './headingTexts';
import links from './links';
import { testimonialsV2 } from './testimonialsv2';

const data = {
  footer,
  gifts: marketplace_products,
  headingTexts,
  links,
  topics,
  testimonials: testimonialsV2,
};

export default data;
